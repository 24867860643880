<template>
  <div>
    <div class="c-section">
      <h1 class="c-title2 c-title2--mb16">
        {{ WORDS.LINE_ID }}との{{ WORDS.LINK }}が{{
          WORDS.LINK_CANCEL
        }}されました
      </h1>
      <p class="link-line-unlink-text">
        もう一度、{{ WORDS.LINE_ID }}と{{ WORDS.LINK }}する場合は下記から{{
          WORDS.LINK
        }}を行ってください。
      </p>

      <div class="p-link-line__actions">
        <ButtonLineLogin :href="cosmbiWebloginUrl" fluid />

        <Button
          :href="$customUrlScheme.page('root/home/1')"
          style-type="tertiary"
          fluid
          flat>
          {{ WORDS.BACK_TOP }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  setup: () => {
    const cosmbiWebLoginUrl = computed(
      () => process.env.VUE_APP_COMSBI_WEBLOGIN_URL
    );
    return {
      WORDS,
      cosmbiWebLoginUrl
    };
  }
});
</script>
