var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-section" }, [
      _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
        _vm._v(
          " " +
            _vm._s(_vm.WORDS.LINE_ID) +
            "との" +
            _vm._s(_vm.WORDS.LINK) +
            "が" +
            _vm._s(_vm.WORDS.LINK_CANCEL) +
            "されました "
        ),
      ]),
      _c("p", { staticClass: "link-line-unlink-text" }, [
        _vm._v(
          " もう一度、" +
            _vm._s(_vm.WORDS.LINE_ID) +
            "と" +
            _vm._s(_vm.WORDS.LINK) +
            "する場合は下記から" +
            _vm._s(_vm.WORDS.LINK) +
            "を行ってください。 "
        ),
      ]),
      _c(
        "div",
        { staticClass: "p-link-line__actions" },
        [
          _c("ButtonLineLogin", {
            attrs: { href: _vm.cosmbiWebloginUrl, fluid: "" },
          }),
          _c(
            "Button",
            {
              attrs: {
                href: _vm.$customUrlScheme.page("root/home/1"),
                "style-type": "tertiary",
                fluid: "",
                flat: "",
              },
            },
            [_vm._v(" " + _vm._s(_vm.WORDS.BACK_TOP) + " ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }